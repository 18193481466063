.user-list {
    padding: 0;
    margin-bottom: 0.5rem;
    overflow: hidden;

    .user-row {
        padding: 0.5rem 1rem;

        + .user-row {
            border-top: 1px solid #CCC;
        }

        select {
            width: 5em;
            padding: 0 1rem 0 0.5rem;
            border-color: transparent;
            color: #666;
            background-color: transparent;
            transition: all 0.2s;
        }

        .fa-times-circle {
            opacity: 0.3;
            transition: all 0.2s;
        }

        &:hover {
            background: #EEE;

            select {
                border-color: #CCC;
                background-color: #FFF;
            }

            .fa-times-circle {
                opacity: 1;
                color: #900;
                transform: scale(1.5);
            }
        }
    }
}