

$color-brand-1: #435065;
$color-brand-2: #5e667f;
$color-brand-3: #0a8e97;
$color-brand-4: #e04a00;
$color-brand-5: #b93b00;
$color-brand-6: #54b268;
$color-brand-7: #15a4fa;

$color-grey-1: #f3f3f3;
$color-grey-2: #e2e1e1;
$color-grey-3: #b9b9b9;
$color-grey-4: #7b7b7b;

$font-size    : 16px; // in pixels!
$line-height  : 1.4;  // as float!
$gap          : 16px; // in pixels!
$threshold-lg : 74em;
$threshold-md : 56em;
$threshold-sm : 26em;
$border-radius: 3px; //$font-size / 4;