@import "../../styles/variables.scss";

.menu-button-wrap {
    position: relative;
    
    .menu-button-menu {
        display      : none;
        background   : #FFF;
        border       : 1px solid #666;
        border-radius: $border-radius;
        box-shadow   : 0 3px 3px -1px rgba(0,0,0,0.2);
        position     : absolute;
        left         : 0;
        right        : auto;
        top          : 100%;
        width        : max-content;
        min-width    : 100%;
        margin       : 4px 0 0;
        max-height   : 45vh;
        overflow     : auto;
        z-index      : 3;
        padding      : 4px;

        > :not(hr) {
            padding      : 1ex 1em;
            margin: 0;
            font-weight  : normal;
            cursor       : pointer;
            white-space  : nowrap;
            display      : block;

            &:hover, &.selected {
                background: rgba(0, 0, 0, 0.075);
            }

            &.highlighted {
                background: $color-brand-2;
                color: #FFF;
            }

            &:first-child {
                border-radius: ($border-radius - 2) ($border-radius - 2) 0 0;
            }
            &:last-child {
                border-radius: 0 0 ($border-radius - 2) ($border-radius - 2);
            }
        }

        > hr {
            height: 1px;
            margin: 4px 0;
        }
    }

    &.menu-right .menu-button-menu {
        left : auto;
        right: 0;
    }

    &.open .menu-button-menu {
        display: block;
    }
}