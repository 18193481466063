@import "../../styles/variables.scss";

.dialog-overlay {
    position  : fixed;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0.3);
    z-index   : 20000;
}

.dialog {
    position      : absolute;
    top           : 10%;
    left          : 50%;
    transform     : translateX(-50%);
    background    : #FFF;
    z-index       : 20001;
    box-shadow    : 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 5px -1px rgba(0, 0, 0, 0.5);
    border-radius : $gap / 2;
    flex-direction: column;
    display       : flex;
    max-width     : calc(100% - #{$gap} * 2);

    .dialog-header {
        background   : #EEE;
        padding      : $gap / 2 $gap;
        border-radius: $gap / 2 $gap / 2 0 0;
        box-shadow   : 0 -1px 0 rgba(0, 0, 0, 0.07) inset;
        font-weight  : 700;
        display      : flex;
        flex         : 1 0 auto;
        text-shadow  : 0 1px 0 #FFF;
        position     : relative;
        font-size    : 120%;

        > div {
            white-space  : nowrap;
            overflow     : hidden;
            text-overflow: ellipsis;
            padding      : 0 2em 0 0;
        }

        .dialog-close-button {
            position  : absolute;
            top       : 50%;
            right     : calc(#{$gap} - 0.25ch);
            opacity   : 0.4;
            cursor    : pointer;
            transition: all 0.4s;
            transform : translateY(-50%);

            &:hover {
                opacity: 1;
                color  : #900;
            }
        }
    }

    .dialog-body {
        padding    : $gap;
        max-width  : 100%;
        display    : flex;
        flex       : 1 1 auto;
        align-items: stretch;
        overflow   : auto;
        flex-direction: column;
    }

    .dialog-footer {
        background   : #EEE;
        padding      : $gap $gap;
        border-radius: 0 0 $gap / 2 $gap / 2;
        box-shadow   : 0 1px 0 rgba(0, 0, 0, 0.05) inset;
        display      : flex;
        flex         : 1 0 auto;
    }
}


