@import "../../styles/variables.scss";

.contact-dialog {
    width: 100vw;

    .contact-form {
        .flex-row > div,
        .flex-row > label {
            padding: 4px;
            white-space: nowrap;
        }

        .flex-row > div {
            padding-bottom: 1rem;
        }

        label {
            font-weight: 600;
            line-height: 1.2em;
        }
    }

    .error-message {
        color        : #900;
        background   : rgba(153, 0, 0, 0.08);
        padding      : 1em;
        margin       : -16px -16px 1em;
        border-bottom: 1px solid #f1dddd;
        box-shadow   : 0 1px 2px -1px #FFF inset;
        text-shadow  : 0 1px 0 #FFF;
    
        > span {
            margin-right: 1.5em;
        }
    }
    
    .sending-loader {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #FFF;
        display: flex;
        align-items: center;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border-radius: $gap / 2;
        color: #666;
        font-weight: 600;
        padding: $gap;
        white-space: pre-wrap;
    }
}

@media only screen and (min-width: 768px) {
    .contact-dialog {
        width: 600px;
        .contact-form .flex-row > div {
            padding-bottom: 4px;
        }
    }
}

@media only screen and (min-width: 1200px) {
    .contact-dialog {
        width    : 800px;
    }
}




