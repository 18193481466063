@import "./variables.scss";

* {
    box-sizing: border-box;
}

html {
    color      : $color-brand-1;
    background : #FFF;
    font-size  : $font-size;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    line-height: $line-height;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
select,
p,
blockquote,
th,
td {
    margin: 0;
    padding: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

fieldset,
img {
    border: 0;
}

address,
body,
button,
caption,
cite,
code,
dfn,
em,
input,
optgroup,
option,
select,
strong,
textarea,
th,
var {
    font:inherit;
}

del,
ins {
    text-decoration: none;
}

li {
    list-style: none;
}

caption,
th {
    text-align: left;
}

h1 { font-size: 200%; }
h2 { font-size: 150%; }
h3 { font-size: 130%; }
h4 { font-size: 110%; }
h5 { font-size: 100%; }
h6 { font-size: 90%;  }

q:before,
q:after {
    content: '';
}

abbr,
acronym {
    border: 0;
    font-variant: normal;
}

sup {
    vertical-align: baseline;
}

sub {
    vertical-align: baseline;
}

/*because legend doesn't inherit in IE */
legend {
    color: $color-brand-1;
}

// -----------------------------------------------------------------------------

#pop-up-wrapper {
    position  : fixed;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0.7);
    z-index   : 2;
    display   : none;
}

body {
    margin: 0;
    padding: 0;
    background: $color-grey-2;
    color: $color-brand-1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: -webkit-fill-available;
    min-width: -webkit-fill-available;

    &.has-popup {
        // overflow: hidden;

        #root {
            filter: blur(10px);
        }

        #pop-up-wrapper {
            display: block;
        }
    }
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
    max-width : $threshold-lg;
    margin    : 0 auto;
    padding   : 0 $gap;
    padding-right: calc(max(#{$gap}, env(safe-area-inset-right)));
    padding-left: calc(max(#{$gap}, env(safe-area-inset-left)));
}

.main {
    flex: 1;
    width: 100%;
}

a {
    color: $color-brand-1;
    text-decoration: none;

    &:hover {
        color: #e04a00;
        &.underline {
            text-decoration: underline;
        }
    }
}

hr {
    height: 8px;
    background: #f3f3f3;
    margin: 2em 0;
    clear: both;
    border: none;

    &.small {
        height: 4px;    
    }
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0.5em 0;
}

.spacer {
    flex: 1 1;
}

.loader i {
    color: $color-brand-7;
}

// ----------------------------------------------------------
.card {
    margin-bottom: $gap;
    border-bottom: 5px solid $color-grey-3;
    border-radius: $border-radius;
    background-color: #FFF;

    .card-body {
        padding: 1rem;
    }

    > footer {
        background: #f3f3f3;
        padding   : 1rem;
    }

    > header {
        background   : #f3f3f3;
        padding      : 0.5rem 1rem;
        border-bottom: 1px solid #EEE;
        border-radius: $border-radius $border-radius 0 0;
        font-weight  : bold;
        font-size    : 120%;
    }
}

.text-center { text-align: center; }

.badge {
    border-radius   : 50px;
    font-size       : .8rem;
    text-transform  : uppercase;
    color           : #fff;
    padding         : .36em .75em .24em;
    font-weight     : 400;
    letter-spacing  : 1px;
    background-color: $color-brand-6;
    display         : inline-block;
    // vertical-align  : text-bottom;
    vertical-align  : middle;
    margin          : 0 0.25rem;
    line-height     : 1;
}

.alert {
    padding: 0.5rem 1rem;
    margin : 0.5rem 0;
    border-radius: 4px;

    &.alert-success {
        background: $color-brand-6;
        color: #FFF;
    }
    &.alert-danger {
        background: $color-brand-5;
        color: #FFF;
    }
    &.alert-warning {
        color: $color-brand-4;
        background: #FFF;
        box-shadow: 0 0 0 1px $color-brand-4 inset;
    }
}

.fg-brand-1 { color: $color-brand-1; }
.fg-brand-2 { color: $color-brand-2; }
.fg-brand-3 { color: $color-brand-3; }
.fg-brand-4 { color: $color-brand-4; }
.fg-brand-5 { color: $color-brand-5; }
.fg-brand-6 { color: $color-brand-6; }
.fg-grey-1 { color: $color-grey-1; }
.fg-grey-2 { color: $color-grey-2; }
.fg-grey-3 { color: $color-grey-3; }
.fg-grey-4 { color: $color-grey-4; }

.bg-brand-1 { background-color: $color-brand-1; }
.bg-brand-2 { background-color: $color-brand-2; }
.bg-brand-3 { background-color: $color-brand-3; }
.bg-brand-4 { background-color: $color-brand-4; }
.bg-brand-5 { background-color: $color-brand-5; }
.bg-brand-6 { background-color: $color-brand-6; }
.bg-grey-1 { background-color: $color-grey-1; }
.bg-grey-2 { background-color: $color-grey-2; }
.bg-grey-3 { background-color: $color-grey-3; }
.bg-grey-4 { background-color: $color-grey-4; }

// ------------------------------------------------------------
// button, input[type="button"], input[type="submit"], .btn {
//     border          : none;
//     cursor          : pointer;
//     font-size       : inherit;
//     line-height     : inherit;
//     border-radius   : 3px;
//     font-family     : "Open Sans", sans-serif;
//     color           : #fff;
//     padding         : .5em 1em;
//     display         : inline-block;
//     font-weight     : 400;
//     background-color: #0a8e97;
    
//     &:hover {
//         background-color: #097279;
//         color           : #fff;
//     }
// }
@import "./mixins.scss";
@import "./forms.scss";

// Grid ------------------------------------------------------
.col-6 {
    width: 50%;
    display: inline-block;
    vertical-align: top;

    &:nth-child(odd) {
        padding: 0 1rem 0 0;
    }
    &:nth-child(even) {
        padding: 0 0 0 1rem;
    }
    &.left {
        padding: 0 1rem 0 0;
    }
    &.right {
        padding: 0 0 0 1rem;
    }
}
.padding { padding: $gap; }
.pad-top { padding-top: $gap; }
.pad-right { padding-right: $gap; }
.pad-bottom { padding-bottom: $gap; }
.pad-left { padding-top: $gap; }
.margin { margin: $gap; }
.margin-top { margin-top: $gap; }
.margin-right { margin-right: $gap; }
.margin-bottom { margin-bottom: $gap; }
.margin-left { margin-top: $gap; }

/* GRID --------------------------------------------------------------------- */
.flex {
    $gap: 1ex;

    &-row {
        display       : flex;
        flex-direction: row;
        flex-shrink   : 0;
        flex-grow     : 1;
        flex-wrap     : wrap;
        width         : 100%;
    }

    &-col {
        &-xs, &-sm, &-md, &-lg, & {
            &-0, &-1, &-2, &-3, &-4, &-5, &-6, &-7, &-8, &-9, &-9, &-10 {
                display    : flex;
                align-self : center;
                align-items: flex-start;
                flex-shrink: 0;
                flex-grow  : 0;
                white-space: normal;
                flex-grow  : 1;
                flex-basis : 100%;

                &.left   { text-align: left;   align-items: flex-start; justify-content: flex-start;}
                &.center { text-align: center; align-items: center;     justify-content: center;    }
                &.right  { text-align: right;  align-items: flex-end;   justify-content: flex-end;  }
                &.top    { align-self: flex-start; }
                &.middle { align-self: center; }
                &.bottom { align-self: flex-end; }
            }
        }
    }
}

@mixin switchToNormal() {
    &-0  { flex-grow: 1 ; flex-basis: 100%; }
    &-1  { flex-grow: 1 ; flex-basis: 10% ; max-width: 10%; }
    &-2  { flex-grow: 2 ; flex-basis: 20% ; max-width: 20%; }
    &-3  { flex-grow: 3 ; flex-basis: 30% ; max-width: 30%; }
    &-4  { flex-grow: 4 ; flex-basis: 40% ; max-width: 40%; }
    &-5  { flex-grow: 5 ; flex-basis: 50% ; max-width: 50%; }
    &-6  { flex-grow: 6 ; flex-basis: 60% ; max-width: 60%; }
    &-7  { flex-grow: 7 ; flex-basis: 70% ; max-width: 70%; }
    &-8  { flex-grow: 8 ; flex-basis: 80% ; max-width: 80%; }
    &-9  { flex-grow: 9 ; flex-basis: 90% ; max-width: 90%; }
    &-9  { flex-grow: 9 ; flex-basis: 90% ; max-width: 90%; }
    &-10 { flex-grow: 10; flex-basis: 100%; max-width: 100%;}

    &-left { text-align: left; align-items: flex-start; justify-content: flex-start;}
    &-center { text-align: center; align-items: center; justify-content: center;}
    &-right { text-align: right; align-items: flex-end; justify-content: flex-end; }
    &-top { align-self: flex-start; }
    &-middle { align-self: center; }
    &-bottom { align-self: flex-end; }
}




.flex-col {
    @include switchToNormal();
}

.flex-col-xs {
    @include switchToNormal();
}

@media only screen and (min-width: 768px) {
    .flex-col-sm {
        @include switchToNormal();
    }
}

@media only screen and (min-width: 992px) {
    .flex-col-md {
        @include switchToNormal();
    }
}

@media only screen and (min-width: 1200px) {
    .flex-col-lg {
        @include switchToNormal();
    }
}

@import "../components/Account/Account.scss";
@import "../components/AppList/AppList.scss";
@import "../components/Footer/Footer.scss";
@import "../components/Header/Header.scss";
@import "../components/AppView/AppView.scss";
@import "../components/DefaultImage/DefaultImage.scss";
@import "../components/ListEntry/ListEntry.scss";
@import "../components/MenuButton/MenuButton.scss";
@import "../components/Tag/Tag.scss";
@import "../components/Paginator/Paginator.scss";
@import "../components/Select/Select.scss";
@import "../components/MultiSelect/MultiSelect.scss";
@import "../components/Sidebar/Sidebar.scss";
@import "../components/SlideShow/SlideShow.scss";
@import "../components/ApplicationForm/ApplicationForm.scss";
@import "../components/PatientPicker/PatientPicker.scss";
@import "../components/Dialog/Dialog.scss";
@import "../components/ContactDialog/contact-dialog.scss";
@import "../components/AdvancedSearchForm/AdvancedSearchForm.scss";
@import "../components/UserManager/UserManager.scss";

// @media (max-width: $threshold-md) {
//     .container {
//         max-width: $threshold-md;
//     }
// }
