@import "../../styles/variables.scss";

.app-list {
    margin: auto;

    .apps {
        opacity: 1;
        filter: none;
        transition: all 0.6s;
    }

    .sidebar + & {
        width       : 75%;
        padding-left: $gap * 2;
        display     : inline-block;
    }

    header {
        display      : flex;
        margin       : 0 0 $gap * 2;
        border-bottom: 3px solid rgba(0, 0, 0, 0.1);
        align-items  : baseline;
        padding      : 0 0 0.5rem;
        flex-wrap    : wrap;

        .spacer {
            flex: 0 1 1rem;
        }

        h2 {
            flex  : 100 1 20rem;// 0 30rem;
            margin: 0.5rem 0;
        }

        .sort-selector {
            margin     : 0.5rem 0;
            flex       : 1 1 11.5rem;
            font-weight: 400;
            font-size  : 1rem;
            
            input {
                border-color: transparent;
            }
            
            .select-value {
                background: transparent;
            }
    
            &:hover, &:focus-within {
                .select-value {
                    background: #FFF;
                }
                input {
                    border-color: #999;
                }
            }
        }
    }

    .card {
        margin-bottom: $gap * 2;
    }

    .group-header {
        display: flex;
        padding: 1ex 0;
        align-items: center;

        h4 {
            padding: 0 1ex;
            color: $color-brand-3;
            text-shadow: 0 1px 1px #FFF;
            font-weight: 500;
            letter-spacing: 1px;
        }

        &:before, &:after {
            content: "";
            flex: 1;
            border-top: 1px solid rgba(0, 0, 0, 0.3);
            border-bottom: 1px solid #fff;
            height: 0;
            opacity: 0.5;
        }
    }
}

@media (max-width: $threshold-md) {
    .app-list, .sidebar + .app-list {
        width  : 100%;
        padding: 0;
    }

    .main {
        min-height: 0;
    }
}

@media (max-width: $threshold-sm) {
    .app-list {
        header .sort-selector {
            input {
                border-color: #CCC;
            }
            .select-value {
                background: #FFF;
            }
        }
    }
}
