.card.account-card {
    > .card-body {
        padding: 4em;
    }
}

.identities-table {
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    th {
        font-weight: 600;
    }

    td, th {
        padding: 5px 15px;
    }

    thead th {
        border-bottom: 1px solid #CCC;
    }

    tbody {
        td, th {
            border-bottom: 1px solid #EEE;
        }

        tr:hover {
            background: #F8F8F8;
        }
    }

    a {
        color: #06F;
    }
}

.auth-flash-message {
    color: $color-brand-5;
    padding: 1em 2em 1em 4em;
    background: #FEE;
    border-radius: 0.5em;
    margin-bottom: 2rem;
}

.auth-flash-message-icon {
    margin-left: -3rem;
    width: 3rem;
    font-size: 2rem;
    line-height: 3rem;
    float: left;
    text-align: left;
}