@import "../../styles/variables.scss";

.main-footer {
    clear     : both;
    background: #FFF;
    text-align: center;
    margin-top: $gap * 2;
    padding   : 1rem 0;

    a {
        margin: 0.75rem 0;
        padding: 0.25rem 0.75rem;
        white-space: nowrap;
        border-radius: 50px;

        &:hover {
            color: $color-brand-1;
            background: #ebf7ff;
        }
    }

    .container {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        justify-content: center;
    }

    .left {
        display  : flex;
        flex     : 1 1 52rem;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .right {
        display: flex;
        flex: 1 1 19rem;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
}