@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.select {
    // @include form-control();
    display : inline-block;
    position: relative;
    outline : none;
    width: 100%;

    &:after {
        content     : "";
        width       : 0;
        height      : 0;
        border-width: 5px 5px 0;
        border-color: #777 transparent transparent;
        border-style: solid inset none;
        position    : absolute;
        z-index     : 2;
        top         : 50%;
        right       : 1em;
        margin      : -1px -2px 0 0;
    }

    input {
        // position: absolute;
        // z-index: 0;
        // width: 100%;
        color: transparent !important;
        background: transparent !important;
    }
    .select-value {        
        // border-radius: 3px;
        // border       : 1px solid #CCC;
        // background   : #FFF;
        padding      : 0 2em 0 0;
        cursor       : default;
        color        : $color-brand-1;
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        left: 1px;
        z-index: 2;
        border-radius: $border-radius;
        padding: 0.5em 1em;
        background: #FFF;
        white-space  : nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // min-height   : 1.2143em;

        &:empty:after {
            content: "Please select";
            color: rgba(0,0,0,0.5);
        }
    }

    .select-value-prefix {
        color: #777;
    }

    .select-label-prefix {
        display: inline-block;
        width: 1.4rem;
    }

    .select-option-description {
        margin-left: 1.4rem;
        font-size: 86%;
        opacity: 0.6;
        white-space: normal;
    }

    .select-options {
        display      : none;
        // box-sizing   : content-box;
        background   : #FFF;
        border       : 1px solid #666;
        border-radius: $border-radius;
        box-shadow   : 0 3px 3px -1px rgba(0,0,0,0.2);
        position     : absolute;
        left         : 0;
        right        : 0;
        top          : 100%;
        width: fit-content;
        min-width    : 100%;
        margin       : 4px 0 0;
        max-height   : 45vh;
        overflow     : auto;
        z-index      : 3;
        padding      : 1px;

        .select-option {
            padding      : 1ex 1em;
            font-weight  : normal;
            cursor       : pointer;
            white-space  : nowrap;

            &:hover, &.selected {
                background: rgba(0, 0, 0, 0.075);
            }

            &.highlighted {
                background: $color-brand-2;
                color: #FFF;
            }

            &:first-child {
                border-radius: ($border-radius - 2) ($border-radius - 2) 0 0;
            }
            &:last-child {
                border-radius: 0 0 ($border-radius - 2) ($border-radius - 2);
            }
        }
    }

    &.open .select-options {
        display: block;
    }
}
