@import "../../styles/variables.scss";

.app-form {
    padding: $gap * 4;

    &.loading {
        filter: grayscale(1);
        opacity: 0.7;
        pointer-events: none;
        transition: all 1s;
    }

    header, footer {
        display: flex;
        align-items: center;
        // justify-content: stretch;
        flex-wrap: wrap;
        padding: 0;
        // width: 100%;
        // flex: 1;
        // box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5) inset;

        .form-buttons {
            display: flex;
        }

        h1 {
            white-space: nowrap;
            margin: 0;
        }

        .flex1 {
            flex: 1;
        }

        label {
            padding: 1rem;
            display: flex;
            align-items: center;
            white-space: nowrap;
        }

        .btn {
            margin: 0.5rem;
            // min-width: 6em;
        }
    }

    header {
        border-bottom: 8px solid #f3f3f3;
        padding-bottom: 0.5rem;
    }
    
    footer {
        background : #FFF;
        border-top : 8px solid #f3f3f3;
        margin-top : 4rem;
        padding-top: 0.5rem;
    }

    .image-manager {
        margin: 0 0 2rem;
        .image-manager-thumbnails {
            margin: 1rem -5px;

            .screenshot-wrapper {
                position: relative;
                padding : 75% 0 0;
                line-height   : 7em;
                vertical-align: top;
                text-align    : center;
                background-color: white;
                background-image:
                    linear-gradient(45deg, #eee 25%, transparent 25%),
                    linear-gradient(-45deg, #eee 25%, transparent 25%),
                    linear-gradient(45deg, transparent 75%, #eee 75%),
                    linear-gradient(-45deg, transparent 75%, #eee 75%);
                background-size: 20px 20px;
                background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
                box-shadow: 0 0 1px 0 #000;
            }
            
            .screenshot {
                position      : relative;
                width         : 33.3333%;
                display       : inline-block;
                border        : 5px solid #FFF;
                box-shadow    : 0 0 1px 0 #000 inset;
                padding       : 4px;
                border-radius : $border-radius + 5;
                background    : #EEE;
                vertical-align: top;
                

                .app-icon {
                    margin: auto;
                    position  : absolute;
                    margin: 0 auto;
                    width: 75%;
                    height: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                
                .fa-camera {
                    font-size: 3em;
                    vertical-align: middle;
                    color: rgba(127, 127, 127, 0.4);
                    position  : absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                img {
                    // border   : 1px #CCC dashed;
                    max-width : 100%;
                    max-height: 100%;
                    position  : absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                footer {
                    // height: 2rem;
                    margin: 0;
                    border-top: 1px solid #CCC;
                    background: transparent;
                    padding: 0;
                    text-align: center;
                }

                button {
                    font-size: 80%;
                    width: 9em;
                    max-width: 100%;
                    margin: 5px auto 1px;
                    line-height: 1;
                    padding: 5px;
                }

                .badge {
                    font-size: 10px;
                    color: #FFF;
                    width: 8em;
                    margin: 7px auto 6px;
                    white-space: nowrap;
                    text-align: center;
                }

                .del-btn {
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    border-radius: 50%;
                    font-size: 16px;
                    z-index: 2;
                    color: #666;
                    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.25), 0 0 0px 10px #FFF inset;
                    padding: 2px;
                    cursor: pointer;
                    vertical-align: top;
                    transform: scale(1.01);

                    &:hover {
                        color: #000;
                        box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.25), 0 0 5px 0px rgba(0, 0, 0, 0.5), 0 0 0px 10px #FFF inset;
                        transform: scale(1.2);
                    }
                }

                .meta {
                    font-size: 80%;
                    text-align: center;
                }

                &.has-error {
                    background-color: rgb(255, 229, 229);
                    * {
                        color: #900;
                    }
                    footer {
                        display: none;
                    }
                }
            }
        }

        .error-message {
            background-color: rgb(255, 229, 229);
            color: #900;
            box-shadow: 0 0 1px 0 rgb(192, 147, 147) inset;
            margin: 0 0 2px;
            padding: 0.25rem 0.5rem;
            border-radius: 4px;
            font-size: 85%;
            li {
                padding-left: 18px;
                > .fa-exclamation-triangle {
                    display: inline-block;
                    width: 18px;
                    margin-left: -18px;
                    font-size: 80%;
                    vertical-align: baseline;
                }
            }
        }
    }

    .section-header {
        font-size: 2em;
        margin: 3em 0 1em;
        border-bottom: 8px solid #f3f3f3;
        padding-bottom: 0.5rem;
    }

    .checkbox-rows {
        display: flex;
        flex-direction: column;

        .checkbox-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            line-height: 2.7em;

            input {
                line-height: 1;
            }
            
            label {
                white-space: nowrap;
                flex: 1 1 14em;
            }

            &:has(:not(:checked)) input[type="url"] {
                opacity: 0.5;
            }
        }
    }

    .error-wrapper {
        padding-top: 1rem;
    }

    .errors {
        background: rgb(255, 227, 220);
        color: #900;
        padding: 0.5rem 1rem;
        border: 1px solid rgb(243, 210, 202);
        border-radius: $border-radius;
        overflow: auto;
        white-space: pre-wrap;
    }
}

@media (max-width: $threshold-lg) {
    .app-form {
        .col-6 {
            width: 100%;
            display: block;
            padding: 0;
        }

        .form-group {
            margin: 4rem 0 1rem;
        }

        header, footer {
            flex-direction: column;
            .form-buttons {
                justify-content: space-between;
                flex-direction: row;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                width: 100%;
                .btn {
                    flex: 1;
                }
                label {
                    display: flex;
                    flex: 1 0 100%;
                    align-items: center;
                    align-self: center;
                    justify-self: center;
                    justify-content: center;
                    text-align: center;
                }
            }
        }

        footer {
            .form-buttons {
                order: 1;
            }
            label {
                order: 2;
            }
            p.flex1 {
                order: 3;
            }
        }
    }
}

@media (max-width: $threshold-md) {
    .app-form {
        padding: $gap * 2;
        // margin: 0 -$gap;
        // border-radius: 0;
    }
}

@media (max-width: $threshold-sm) {
    .app-form {
        padding: $gap;
        margin: 0 -$gap;
        border-radius: 0;
    }
}

