@import "../../styles/variables.scss";


.main-header {
    background   : #FFF;
    margin-bottom: $gap * 2;
    line-height  : 1;

    .main-header-container {
        display        : flex;
        align-items    : center;
        justify-content: center;
        flex-wrap      : wrap;
    }

    .heading {
        font-size  : 1.8em;
        line-height: 40px;//inherit;
        // white-space: nowrap;
        text-align: center;
        flex      : 0 1 21rem;
        padding   : $gap 0;
        span {
            white-space: nowrap; 
        }
    }

    .logo {
        width: 163px;
        height: 28px;
        // width         : auto;
        // height        : 1.8;
        vertical-align: middle;
        margin        : 0 0.5rem 0 0;
    }

    .spacer {
        flex: 1;
    }

    a {
        color: $color-brand-1;
        text-decoration: none;
    }

    .links {
        display    : flex;
        flex       : 3 1 auto;
        line-height: 1;
        text-align : center;
        padding    : 0 $gap;
        justify-content: center;
        flex-wrap: wrap;
    
        a {
            // flex       : 1 1;
            padding    : 1ex 1em;
            white-space: nowrap;
            &:hover, &.active {
                background-color: #ebf7ff;
                border-radius   : 50px;
            }
        }
    }

    .right {
        display: flex;
        flex: 3 1 25%;
        padding  : $gap 0;
        justify-content: center;
    }

    form {
        flex: 1;
        position: relative;
        max-width: 24rem;

        .search-field-wrap {
            position: relative;
        }
        .advanced-search-link-wrap {
            display: none;
            z-index: 200;
            padding: 1px;
            background: #FFF;
            border-radius: 4px;
            position: absolute;
            top: 100%;
            left: 0;
            min-width: 100%;
            border       : 1px solid #AAA;
            box-shadow: 0 3px 7px -4px rgba(0, 0, 0, 0.5);
            margin-top:1px;
            a {
                color: $color-brand-4;
                display: block;
                padding: 1rem 1.5rem;
                white-space: nowrap;
                &:hover {
                    background-color: #f3f3f3;
                }
            }
        }
        

        input {
            padding-right: 3em;
            // width: 100%;
            min-width: 10rem;
            // display: block;

            &:focus + .advanced-search-link-wrap {
                display: block;
            }
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: $color-brand-2;
            padding: 0 1em;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
    }

    .logout-link {
        // background-color: $color-brand-2;
        // border-radius: 3px;
        // color: #fff;
        // padding: 0 1em;
        // display: inline-block;
        // line-height: 32px;
        // vertical-align: top;
        margin: 0 0 0 $gap/2;
        // width: 5em;

        .user-image {
            height: 36px;
            height: calc(1.4rem + 10px);
            margin: -7px -11px -7px 0.5rem;
            border-radius: 50%;
            // border: 1px solid #5e667f;
        }
    }
}
