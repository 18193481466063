@import "../../styles/variables.scss";


.app-list-entry {
    transition: border-bottom-color 0.3s linear 0.1s;

    &:hover {
        border-bottom-color: $color-grey-4;
    }

    .app-icon {
        display: block;
        float  : left;
        width  : 7rem;
        height : 7rem;
        margin : 0 1rem 1rem 0;
    }

    .app-list-entry-body {
        padding : 1rem;
        position: relative;
        word-wrap: break-word;

        &:after {
            content: "";
            display: table;
            clear  : both;
        }
    }

    h3 {
        margin : 0 9rem 0 0;
    }

    h4 {
        margin: .25em 9rem .5em 0;
        color : $color-grey-4;
    }

    footer {
        font-size: 90%;

        > div {
            display: inline-block;
            margin : 0 1rem 0 0;

            b {
                margin-right: 0.5em;
            }
        }
    }

    .buttons {
        position: absolute;
        top     : 1rem;
        right   : 1rem;
        padding : 0 0 1rem 1rem;
    }
}

@media (max-width: $threshold-sm) {
    .app-list-entry {

        .app-icon {
            max-width: 20vw;
            max-height:  20vw;
            margin-bottom: 0.5rem;
        }

        footer {
            margin: 0;
        }

        .buttons {
            text-align: right;
            position: static;
        }

        h3 {
            margin: 0;
        }
    
        h4 {
            margin: .25em 0 .5em 0;
            color : $color-grey-4;
        }
    }
}