@import "../../styles/variables.scss";

.tag {
    border       : 1px solid #CCC;
    background   : #EEE;
    padding      : ($font-size * 0.2) ($font-size * 0.4);
    margin       : 2px 2px;
    border-radius: $border-radius * 0.75;
    line-height  : 1.1;
    white-space  : nowrap;
    display      : inline-block;

    .close-btn {
        margin        : 0 0 0 0.25em;
        opacity       : 0.5;
        line-height   : inherit;
        vertical-align: text-bottom;
    }

    &:hover .close-btn {
        opacity: 0.8s;
    }

    .close-btn:hover {
        opacity: 1;
        color: $color-brand-5;
    }
}