@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.multi-select {
    @include form-control();
    padding : round($font-size * 0.5);
    display : inline-block;
    position: relative;
    z-index : 1;
    outline : none;

    &:after {
        content     : "";
        width       : 0;
        height      : 0;
        border-width: 5px 5px 0;
        border-color: #777 transparent transparent;
        border-style: solid inset none;
        position    : absolute;
        z-index     : 2;
        top         : 50%;
        right       : 1em;
        margin      : -1px -2px 0 0;
    }

    .select-value {        
        // border-radius: 3px;
        // border       : 1px solid #CCC;
        // background   : #FFF;
        padding      : 0 2em 0 0;
        cursor       : default;
        color        : $color-brand-1;
        margin       : -5px;
        z-index: 1;
        position: relative;
        // min-height   : 1.2143em;

        &:empty:before {
            content: "Please select";
            color: rgba(0,0,0,0.5);
            display: inline-block;
            padding: ($font-size * 0.23);
            
            // z-index: -1;
            // position: relative;
            // background: #FFF;
        }

        // .tag {
        //     padding: 3px 2px 3px 6px;
        //     margin: 2px 2px;
        //     line-height: 22px;
        // }
    }

    .select-options {
        display      : none;
        box-sizing   : borders-box;
        background   : #FFF;
        border       : 1px solid #666;
        border-radius: $border-radius;
        box-shadow   : 0 3px 3px -1px rgba(0,0,0,0.2);
        position     : absolute;
        left         : -1px;
        right        : -1px;
        top          : 100%;
        // min-width    : 100%;
        margin       : 4px 0 0;
        max-height   : 45vh;
        overflow     : auto;
        z-index      : 2;
        padding      : 1px;

        // &.max {
        //     color: #666;
        //     text-align: center;
        //     padding      : 1ex 1em;
        // }

        .select-option {
            padding      : 1ex 1em;
            font-weight  : normal;
            cursor       : pointer;
            white-space  : nowrap;

            &:hover, &.selected {
                background: rgba(0, 0, 0, 0.075);
            }

            &.highlighted {
                background: $color-brand-2;
                color: #FFF;
            }

            &.disabled {
                color: #666;
                background: #FFF;
                cursor: not-allowed;

                &.highlighted {
                    background: #EEE;
                }
            }

            &:first-child {
                border-radius: ($border-radius - 2) ($border-radius - 2) 0 0;
            }

            &:last-child {
                border-radius: 0 0 ($border-radius - 2) ($border-radius - 2);
            }
        }

        .select-options-message {
            color     : #C33;
            background: rgb(255, 241, 236);
            text-align: center;
            padding   : 1ex 1em;
            font-weight: bold;
            margin-bottom: 1px;
            text-shadow: 0 1px 0 #FFF;
        }
    }

    &:focus {
        z-index: 3000;
        .select-options {
            display: block;
        }
    }

    .error {
        display: none;
        position: absolute;
        color: #cc2900;
        background: #ffe8e2;
        top: 3px;
        bottom: 3px;
        border-radius: 4px;
        padding: 0.2em 1rem;
        right: 2em;
        z-index: 30000000;
        font-size: 90%;
        line-height: $font-size * $line-height + 2px;
        margin-right: 5px;
        cursor: default;
    }

    &.invalid:focus, &.invalid:hover {
        .error {
            display: block;
        }
    }

    .validation-helper {
        height: 1px;
        padding: 0;
        border: 0;
        opacity: 0;
        outline: 0;
        position: absolute;
        left: 0;
        width: 100%;
    }
}
