@import "./mixins.scss";
@import "./variables.scss";

input[type="text"],
input[type="url"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="file"],
input[type="phone"],
select,
textarea,
.form-control {
    @include form-control();
}

select[size] {
    padding: 0.4em;
}

select {
    background: #FFF url(caret-down.svg) 100% center no-repeat;
    background-size: auto;
    padding-right: 18px;
    background-size: 22px 14px;
    appearance: none;
}

textarea {
    resize: vertical;
    // line-height: inherit;
    &:disabled {
        // fix a bug in Chromium/Linux
        filter: grayscale(1);
    }
}

::placeholder,
::-webkit-input-placeholder {
    color: #999;
}

::-webkit-file-upload-button {
    @include file-selector-button();
}
::-ms-browse {
    @include file-selector-button();
}
::file-selector-button {
    @include file-selector-button();
}
:disabled::-webkit-file-upload-button {
    @include disabled-file-selector-button();
}
:disabled::-ms-browse {
    @include disabled-file-selector-button();
}
:disabled::file-selector-button {
    @include disabled-file-selector-button();
}

input[type="checkbox"],
input[type="radio"] {
  margin: 0 1ex 0 0;
}

.form-description {
    color: #999;
    font-size: 82%;
    margin-bottom: 0.4rem;
}

fieldset {
    border: 0 none;
}

.form-group {
    margin: 1rem 0;

    &:last-of-type {
        margin-bottom: 0    
    }

    // label {
    //     // display: block;
    //     // margin-bottom: 0.25rem;
    //     // color: $color-brand-1;
    // }

    // input, textarea {
    //     // display: block;
    //     // width: 100%;
    //     // font-size: inherit;
    //     // font-family: inherit;
    // }

    // input[type="checkbox"], input[type="radio"] {
    //     display       : inline-block;
    //     line-height   : inherit;
    //     vertical-align: top;
    //     width         : 16px;
    //     height        : 16px;
    //     margin        : 3px 4px 0 0;
    //     padding       : 0;

    //     + .d-inline-block {
    //         max-width: calc(100% - 16px);
    //     }
    // }
}

.btn {

    // white-space: nowrap;
    // @include button(rgba(0, 0, 0, 0.1));

    + .btn {
        margin-left: 1ex;
    }

    &.btn-sm {
        font-size: small;

        padding: 2px 8px !important;
        min-height: 0 !important;

        &:not(:disabled) {
            &:active, &.active {
                padding: 3px 7px 1px 7px !important;
            }
        }
    }

    &.btn-brand-1 {
        @include button($color-brand-1);
    }
    &.btn-brand-2 {
        @include button($color-brand-2);
    }
    &.btn-brand-3 {
        @include button($color-brand-3);
    }
    &.btn-warning {
        @include button(rgb(255, 166, 0));
    }
    &.btn-secondary {
        @include button(#DDD);
    }
    &.btn-danger {
        @include button(rgb(211, 49, 0));
    }
    &.btn-success {
        @include button(rgb(55, 151, 10));
    }
}

.btn-group {
    // display: table;
    // border-collapse: collapse;
    .btn {
        display: inline-block;
        border-radius: 0;

        &:first-child {
            border-radius: $border-radius 0 0 $border-radius;
        }
        &:last-child {
            border-radius: 0 $border-radius $border-radius 0;
        }

        + .btn {
            margin-left: -1px;
        }

        &:hover, &:active, &.active, &:focus {
            position: relative;
            z-index: 2;
        }
    }
}

.input-group {
    display: flex;
    .btn {
        z-index: 2;
    }
    input, .btn, select, .form-control {
        border-radius: 0;
        position: relative;
        margin: 0 0 0 -1px;

        &:first-child {
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
            margin-left: 0;
        }
        &:last-child {
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
        &:focus {
            z-index: 3;
        }
    }
}