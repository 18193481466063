@import "../../styles/variables.scss";

.app-view {
    padding: 4rem;

    .app-icon {
        float  : left;
        margin : 0 1em 1em 0;
    }

    h1 {
        margin: 0;
        line-height: 1;
    }

    h3 {
        margin: 0.5rem 0;
        line-height: 1.2;
    }

    h4 {
        margin: 0;// 0 0.5em;
    }

    a {
        color: $color-brand-4;
    }

    p {
        margin: 0.5em 0;
    }

    .buttons {
        margin: -0.5em;
        display: inline-flex;
        .btn {
            margin: 0.5em;
        }
    }

    .grid-container {
        margin: 0 -$gap;
        display: flex;
        flex-wrap: wrap;
        section {
            min-width: 14rem;
            flex: 1 0 50%;
            padding: 0 $gap;
        }
    }

    .ehr-link, .os-link {
        margin: 0 0 0 2px;
        padding: 4px 0 4px 2px;
        border-radius: 5px;
        background: #FFF;
        color: #999;
        transition: all 0.4s;

        &:hover {
            background: $color-brand-4;
            color: #FFF;
            padding: 4px 8px;
            font-weight: bold;
            &:before {
                content: "Listing ";
            }
        }
    }
}

.preview-banner {
    background    : linear-gradient(90deg, $color-brand-2, transparent 120%);
    padding       : 0.5rem 1rem;
    margin        : 1rem 0;
    font-weight   : bold;
    text-transform: uppercase;
    color         : #FFF;
    border-radius : $border-radius;
    // box-shadow    : 0 0 1px 0 rgba(0, 0, 0, 0.8) inset;
    font-size: 20px;
    // text-shadow: 0 0 1px #000;

    display: flex;
    align-items: center;

    span {
        display: flex;
        flex: 1;
    }

    .btn {
        font-size: 1rem;
        // float: right;
        // padding: 1px 5px;
        margin-right: -0.5rem;
        display: flex;
    }
}

@media (max-width: $threshold-md) {
    .app-view {
        padding: 2rem;


        .grid-container {
            section {
                flex: 1 0 100%;
            }
        }
    }    
}

@media (max-width: $threshold-sm) {
    .app-view {
        margin: $gap (-$gap);
        border-radius: 0;
    }
}
