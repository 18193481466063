@import "../../styles/variables.scss";

.pagination-wrapper {
    text-align: center;

    // .btn {
    //     min-width: 5em;
    // }

    a.page-link {
        // $contrast      : 85;
        // $color         : contrast($bg, 50, lighten($bg, $contrast), darken($bg, $contrast));

        // // background     : $bg linear-gradient(lighten($bg, 5%), darken($bg, 5%));
        // background     : #AAA;
        // border         : none;
        line-height    : 2;
        // // font-size      : inherit;
        // color          : green;
        border-radius  : $border-radius;
        vertical-align : top;
        text-decoration: none;
        // cursor         : default;
        display        : inline-block;
        min-width: 3em;
        // // min-height     : 4.4ex;
        // outline        : 0;
        padding        : 5px 15px;
        // margin: 0 1ex;

        &.active, &:hover {
            background-color: #15a4fa;
            color: #FFF;
        }
    }

    a + a {
        margin-left: 1ex;
    }
}