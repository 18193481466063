.advanced-search-form {
    .flex-row {
        padding: 1rem 0;
        border-top: 2px solid #EEE;
        
        &:first-of-type {
            margin-top: 1rem;    
        }
        > label {
            justify-content: flex-end;
            text-align: right;
            padding: 5px;
            font-weight: 600;
        }
        > div {
            margin: 5px 0;
        }
    }

    footer {
        font-size: inherit;
    }
}

@media (max-width: 1024px) {
    .advanced-search-form .flex-row {
        > label {
            flex: 1 1 30%;
            max-width: 30%;
        }
        > div {
            flex: 1 1 50%;
            max-width: 50%;
        }
    }
}
@media (max-width: 768px) {
    .advanced-search-form .flex-row {
        > label {
            flex: 1 1 40%;
            max-width: 40%;
        }
        > div {
            flex: 1 1 60%;
            max-width: 60%;
        }
        &:first-of-type {
            > label, > div {
                justify-content: left;
                flex: 1 1 100%;
                max-width: 100%;
            }
        }
    }
}