@import "../../styles/variables.scss";

.sidebar {
    float: left;
    width: 25%;
    // margin-top: $font-size * 1.5 * $line-height + $gap * 2;
    padding: 1rem 0;
    position: sticky;
    top: $gap;
    max-height: calc(100vh - #{$gap * 2});
    overflow: auto;

    section {
        position: relative;

        a {
            display: none;
        }
        &.open {
            margin-bottom: 1rem;
            &:before {
                left: 0.9rem;
                border-width: 7px 5px 0 5px;
                border-color: #999 transparent transparent transparent;
                border-style: solid solid none solid;
            }
            a {
                display: block;
            }
        }
    }


    h3 {
        text-transform: uppercase;
        letter-spacing: 1px;
        padding       : .4rem 1rem .4rem 2rem;
        margin        : 0;
        font-size     : 100%;
        cursor        : pointer;
        line-height   : 1;
        user-select   : none;
        position      : relative;

        &:hover {
            background-color: #f3f3f3;
        }

        &:before {
            content     : "";
            position    : absolute;
            top         : 50%;
            left        : 1rem;
            border-width: 5px 0 5px 7px;
            margin      : -5px 0 0;
            border-color: transparent transparent transparent #999;
            border-style: solid none solid solid;
        }
    }

    section.open h3:before {
        margin      : -3px 0 0 -2px;
        border-width: 7px 5px 0 5px;
        border-color: #999 transparent transparent transparent;
        border-style: solid solid none solid;
    }

    a {
        color         : #435065;
        line-height   : 1;
        position      : relative;
        display       : block;
        padding       : .4rem 1rem .4rem 2rem;
        -webkit-tap-highlight-color: rgba(0,0,0,0);

        &:before {
            content : "";
            display : inline-block;
            height  : 100%;
            width   : 5px;
            top     : 0;
            left    : 0;
            position: absolute;
        }

        &:hover {
            background-color: #f3f3f3;
            &:before {
                background: #b9b9b9;
            }
        }

        &.active {
            background-color: #ebf7ff;
            &:before {
                background: #15a4fa;
            }
        }

        > b > i {
            width: 24px;
            font-size: 14px;
        }
    }
}

.btn.sidebar-toggle {
    display: none;
}

@media (max-width: $threshold-md) {
    .sidebar {
        float     : none;
        width     : 100%;
        position  : static;
        margin-top: 0;
        max-height: none;
        display   : none;
    }

    .btn.sidebar-toggle {
        display: inline-block;
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
        margin : 0 0 1rem;

        i {
            margin: 0 0.5rem;
        }
        .fa-times { display: none; }
        .fa-caret-down { display: inline-block; }

        &.visible-sidebar {
            .fa-times { display: inline-block; }
            .fa-caret-down { display: none; }
            + .sidebar {
                display: block;
                margin : 0 0 $gap * 2;
                a, h3 {
                    line-height: 1.8;
                }
            }
        }
    }
}
