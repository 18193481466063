.carousel {
    height            : 100%;
    text-align        : center;
    white-space       : nowrap;
    overflow-x        : scroll;
    overflow-y        : hidden;
    scroll-behavior   : smooth;
    scroll-snap-type  : x mandatory;
    scrollbar-width   : none;
    -ms-overflow-style: none;
    position          : relative;
    user-select       : none;

    &::-webkit-scrollbar {
        display: none;
    }

    .slide {
        display          : inline-flex;
        width            : 100%;
        height           : 100%;
        scroll-snap-stop : normal;
        scroll-snap-align: center;
        vertical-align   : top;
        padding          : 0.5rem;
        flex             : 1;
        align-items      : center;
        justify-content  : center;
    }

    img {
        max-width : 100%;
        max-height: 100%;
        width     : auto;
        display   : block;
    }

    iframe {
        height: 100%;
        width : 100%;
        margin: auto;
    }
}

.stage {
    position: relative;
    height: 100%;

    .prev-link, .next-link {
        position   : absolute;
        top        : 50%;
        width      : 4rem;
        color      : #FFF;
        z-index    : 2;
        font-size  : 200%;
        font-weight: 600;
        cursor     : pointer;
        user-select: none;
        line-height: 4rem;
        height     : 4rem;
        margin-top : -2rem;
        text-shadow: 0 0 1px #000;

        &:hover {
            text-shadow: 0 0 1px #000, 0 0 2px #000;
        }

        &:after {
            position   : absolute;
            top        : 50%;
            left       : 0;
            width      : 100%;
            text-align : center;
            line-height: 2rem;
        }
    }

    .prev-link {
        left: 0;
        border-radius: 0 50% 50% 0;

        &:after {
            content: "〈";
            margin : -1rem 0 auto -1rem;
        }
    }

    .next-link {
        right: 0;
        border-radius: 50% 0 0 50%;

        &:after {
            content: "〉";
            margin : -1rem auto auto 1rem;
        }
    }
}

.slideshow {
    position: relative;
    margin  : 0 auto 2rem;

    .stage {
        padding-top: 75%;

        .carousel {
            position: absolute;
            top     : 0;
            left    : 0;
            right   : 0;
            bottom  : 0;
        }
        
        iframe {
            pointer-events: none;
        }
    }

    .carousel {
        text-align        : center;
        border            : 3px solid #EEE;
        border-radius: 0.5rem;
        background: #EEE;
        // cursor            : pointer;
    }

    .slide {
        display          : inline-flex;
        width            : 100%;
        height           : 100%;
        scroll-snap-stop : normal;
        scroll-snap-align: center;
        cursor           : pointer;
    }

    .thumbnails {
        margin : 1rem 0 0;
        display: flex;

        .slide {
            position     : relative;
            width        : 33%;
            padding-top  : 25%;
            text-align   : center;
            cursor       : pointer;
            box-shadow   : 0 0 0 3px #eee inset;
            border-radius: 0.5rem;
            overflow     : hidden;
            background   : #EEE;

            + .slide {
                margin-left: 0.5rem;
            }

            &:hover, &.active {
                box-shadow: 0 0 0 3px #BBB inset;
            }

            .media {
                text-align: center;
                position  : absolute;
                top       : 50%;
                left      : 50%;
                transform : translate(-50%, -50%);
                font-size : 3rem;
                max-width : 100%;
                max-height: 100%;
                vertical-align: middle;
                padding   : 0.5rem;
            }
        }
    }
}

#pop-up-wrapper {
    .expanded-image-wrapper {
        width: 100%;
        height: 100%;
        padding-right: calc(max(#{$gap}, env(safe-area-inset-right)));
        padding-left: calc(max(#{$gap}, env(safe-area-inset-left)));
        
        .slide {
            padding: 0;
        }

        .close-button {
            position   : absolute;
            top        : 1rem;
            right      : 0;
            font-size  : 2rem;
            color      : #888;
            text-shadow: 0 0 1px #000;
            opacity    : 0.5;
            z-index    : 300;
            line-height: 1;
            padding    : 1rem 2rem 1rem 1rem;
            width      : 0;

            &:hover {
                opacity: 1;
                color: #FFF;
            }
        }
    }
}

@media (orientation: portrait) {
    #pop-up-wrapper .expanded-image-wrapper iframe {
        max-height: 75vw;
    }
}

@media (orientation: landscape) {
    #pop-up-wrapper .expanded-image-wrapper iframe {
        max-height: 100vh;
    }
}
