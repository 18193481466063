
.app-icon {
    position   : relative;
    max-width  : 100%;
    user-select: none;
    display    : block;
    width      : 7em;
    height     : 7em;

    svg {
        width         : 100%;
        height        : 100%;
        top           : 0;
        left          : 0;
        position      : absolute;
        pointer-events: none;

        // These icons should fade-in once mounted to the DOM
        opacity       : 0;
        transition    : opacity 1s;
    }
}
